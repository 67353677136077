import React from 'react'
import PropTypes from 'prop-types'
// import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import BlogPostTemplate from '../components/BlogPostTemplate'
// import Content, { HTMLContent } from '../components/Content'
import { HTMLContent } from '../components/Content'

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        thumbnail={post.frontmatter.thumbnail}
        helmet={<Helmet title={`${post.frontmatter.title} | Blog`} />}
        slug={post.fields.slug}
        tags={post.frontmatter.tags}
        description={post.frontmatter.description}
        title={post.frontmatter.title}
        url={data.site.siteMetadata.siteUrl}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        description
        title
        thumbnail {
          childImageSharp {
            fluid (maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tags
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
